<!--
File: Report_9_Yearly_Condition_Index.vue
Description: produce report showing Region/DEU-wise Condition index.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion"
            :initial_value="selectedRegion" @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RoadsDropdown :label="$t('label.select_road')" v-model="selectedRoad" :region_id="selectedRegion"
            :disabled='!selectedRegion' @input='onRoadChange' />
        </div>
        <!--div class="md-layout-item md-small-size-100 md-size-15">
          <SectionsDropdown :label="$t('label.select_section')" v-model="selectedSection"
            :region_id="selectedRegion" :road_id="selectedRoad" :disabled='!selectedRegion' @input='reloadData' />
        </div-->
        <div class="md-layout-item md-small-size-100 md-size-15">
          <YearsDropdown :label="$t('label.from_year')" v-model="startYear" :initial_value="startYear" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <YearsDropdown :label="$t('label.to_year')" v-model="endYear" :initial_value="endYear" />
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled || emptyData">
            {{ $t('buttons.print') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <div class='md-layout-item md-size-50 md-small-size-100 md-medium-size-50'>
      <canvas v-if="!emptyData" ref="myChart" id="conditionIndexChart" width="200" height="100"></canvas>
    </div>

    <md-card-content>
      <md-progress-spinner v-show="showSpinner" class="md-progress-spinner" :md-diameter="70" md-mode="indeterminate" />

      <md-table class="paginated-table table-striped table-hover" :value="report" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')"
          :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell v-if="!selectedRegion" :md-label="$t('road_network.region')" md-sort-by="region_description">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.survey_year')">
            {{ item.survey_year }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.total_km')" md-numeric>
            {{ item.total_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.total_surveyed_km')" md-numeric>
            {{ item.total_surveyed_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.condition_index')" md-numeric>
            {{ item.condition_index | numFormat }}
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>
  </md-card>
</template>
<script>
import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
import RoadsDropdown from '../Dropdowns/RoadsDropdown.vue'
//import SectionsDropdown from '../Dropdowns/SectionsDropdown.vue'
import YearsDropdown from '../Dropdowns/YearsDropdown.vue'
import Chart from 'chart.js';
import html2canvas from 'html2canvas';
import { numFormat } from "@/store/refdata/format_helpers"
import printReport from '@/mixins/printMixin'

export default {
  name: 'report-yearly-condition-index',
  mixins: [printReport],
  data() {
    return {
      selectedRegion: null,
      //selectedSection: null,
      selectedRoad: null,
      startYear: new Date().getFullYear(),
      endYear: new Date().getFullYear(),
      btnDisabled: true,

      showSpinner: false,
      regionDescr: null,
      roadDescr: null,
      chart: null,
    }
  },
  components: {
    RegionsDropdown,
    RoadsDropdown,
    //SectionsDropdown,
    YearsDropdown,
  },

  mounted() {
    //this.$store.state.Reports.report_9_data = []
    this.$store.commit('SET_REPORT_9', [])
    this.reloadData()
  },

  beforeDestroy() {
    if (this.chart) this.chart.destroy();
  },

  methods: {
    reloadData() {
      this.showSpinner = true
      this.btnDisabled = true
      const report_params = {
        region_id: this.selectedRegion,
        road_id: this.selectedRoad,
        //section_id: this.selectedSection,
        start_year: this.startYear,
        end_year: this.endYear
      }
      this.$store.dispatch('REPORT_9_YEARLY_CONDITION_INDEX', report_params).then(() => {
        this.reloadChart()
        this.btnDisabled = false
        this.showSpinner = false
      })
    },
    reloadChart() {
      if (this.emptyData) return

      let years = { 0: {}, 1: {}, 2: {}, 3: {} }
      let yearsLabels = []

      const yearsSet = new Set(yearsLabels);

      this.report.forEach((item) => {
        if (!yearsSet.has(item.survey_year)) {
          yearsLabels.push(item.survey_year);
          yearsSet.add(item.survey_year); // Добавляем год в Set
        }

        // Используем оператор нулевого слияния для более краткой проверки и установки
        years[0][item.survey_year] ??= item.condition_index;
        // Если нужно сложить значения для года, раскомментируйте следующую строку
        // years[0][item.survey_year] += item.condition_index;
      });

      let data_series = Object.values(years).map(yearObj => Object.values(yearObj));

      if (this.chart) this.chart.destroy(); // Уничтожаем предыдущий график перед созданием нового
      const ctx = document.getElementById('conditionIndexChart').getContext('2d');
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: yearsLabels,
          datasets: [{
            barThickness: 40,
            maxBarThickness: 50,
            label: this.$t('condition.condition_index'),
            data: data_series[0],
            backgroundColor: 'rgba(0, 255, 0, 0.2)',
            borderColor: 'rgba(0, 255, 0, 1)',
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            xAxes: [{ ticks: { beginAtZero: true } }],
            yAxes: [{ ticks: { beginAtZero: true } }]
          },
        }
      });
    },
    print() {
      this.showSpinner = true
      const chartElement = this.$refs.myChart
      html2canvas(chartElement).then((canvas) => {
        let prtHtml = "<h4 style='text-align:center'>" + this.$t('label.reports_title') + "</h4>"
        prtHtml += "<h4 style='text-align:center'>" + this.$t('label.report9_title')
        prtHtml += '&nbsp;' + this.startYear + ' - ' + this.endYear + '<br>'

        let titleFilters = ''
        let tableHeaders = '<tr>'
        if (this.selectedRegion) {
          titleFilters += this.$t('label.from_region', { name: this.report[0].region_description })
        } else {
          tableHeaders += `<th>${this.$t('road_network.region')}</th>`
        }
        if (this.selectedRoad) {
          titleFilters += `, &nbsp;${this.$t('road_network.road')}: ${this.roadDescr}`
        } else {
          //tableHeaders += `<th>${this.$t('road_network.road')}</th>`
        }
        if (titleFilters !== '') prtHtml += `&nbsp;(${titleFilters})`
        prtHtml += '</h4>'

        const chartImage = canvas.toDataURL('image/png');
        prtHtml += `<img src="${chartImage}" style="width:100%;max-width:600px;">`;

        tableHeaders += `<th>${this.$t('road_network.survey_year')}</th>
            <th>${this.$t('road_network.total_km')}</th>
            <th>${this.$t('road_network.total_surveyed_km')}</th>
            <th>${this.$t('condition.condition_index')}</th>
          </tr>`

        let tableRows = ''
        this.report.forEach(item => {
          tableRows += '<tr>'
          if (!this.selectedRegion) tableRows += `<td>${item.region_description}</td>`
          //if (!this.selectedRoad) tableRows += `<td>${this.$t('road_network.road') + '-' + item.road_description}</td>`
          tableRows += `<td>${item.survey_year}</td>
              <td class='numCell'>${numFormat(item.total_km, 3)}</td>
              <td class='numCell'>${numFormat(item.total_surveyed_km, 3)}</td>
              <td class='numCell'>${numFormat(item.condition_index)}</td>
            </tr>`
        })

        this.printReport(prtHtml, tableHeaders, tableRows)
      this.showSpinner = false
      })

    },
    onRoadChange(road_id, roadDescr) {
      this.roadDescr = roadDescr
      this.reloadData()
    },
  },

  computed: {
    report() {
      return this.$store.state.Reports.report_9_data
    },
    emptyData() {
      return this.report.length == 0
    }
  },

  watch: {
    startYear(value) {
      this.reloadData()
    },
    endYear(value) {
      this.reloadData()
    }
  }
}
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>